import React, {useState} from "react"; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {isAuthenticated} from "../services/authentication/authentication";
//Pages
import Login from "../pages/Login/Login";
import MyAccount from "../pages/MyAccount/MyAccount";
import Demands from "../pages/Demands/Demands";
import Clients from "../pages/Clients/Clients";
import Dashboard from "../pages/Dashboard/Dashboard";
import Demand from "../pages/Demand/Demand";
import DemandsPicker from "../pages/DemandsPicker/DemandsPicker";
import DemandsAuction from "../pages/DemandsAuction/DemandsAuction";
import LawyerServices from "../pages/LawyerServices/LawyerServices";
import MenuContext from '../contexts/MenuContext';
import DataPrivacy from "../pages/DataPrivacy/DataPrivacy";
import DataPrivacyClient from "../pages/DataPrivacyClient/DataPrivacyClient";
import EssentialPlan from "../pages/EssentialPlan/EssentialPlan";
import PremiumPlan from "../pages/PremiumPlan/PremiumPlan";
import EssentialPlanClient from "../pages/EssentialPlansClient";
import PremiumPlanClient from "../pages/PremiumPlansClient";
import MyDemandV2 from "../pages/MyDemandV2/MyDemandV2";
import LawyerServicesBonuzPlans from "../pages/LawyerServicesBonuzPlans/LawyerServicesBonuzPlans";
import ReportsPage from "../pages/Admin/ReportsPage";

const Routes = () => {

    const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
   
    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: "/login", state: { from: props.location } }}
                    />
                )
            }
        />
    );

    return (
        <Router>
            <div>
              <MenuContext.Provider value={{sidebarIsOpen, setSidebarIsOpen}}>
                <Switch>
                    <Route path="/" exact>
                        <Redirect to="/dashboard"/>
                    </Route>
                    <Route path="/login" component={Login}/>
                    {/*TODO: Dashboard*/}
                    {<PrivateRoute  path="/dashboard" exact component={Dashboard}/>}
                    {/*Client routes*/}
                    <PrivateRoute  path="/clients" exact component={Clients}/>
                    {/*Demands Routes*/}
                    <PrivateRoute  path="/demands" exact component={Demands}/>
                    <PrivateRoute  path="/demands-auction" exact component={DemandsAuction}/>
                    <PrivateRoute  path="/demands-auction/:demandId" exact component={Demand}/>
                    <PrivateRoute  path="/demands-picker" exact component={DemandsPicker}/>
                    <PrivateRoute  path="/demands-picker/:demandId" exact component={Demand}/>
                    <PrivateRoute  path="/demand/:demandId" exact component={MyDemandV2}/>
                    {/*Account routes*/}
                    <PrivateRoute  path="/account" exact component={MyAccount}/>
                    {/*Add client a service*/}
                    <PrivateRoute  path="/services/bonuz-plans/:clientId" exact component={LawyerServicesBonuzPlans}/>
                    <PrivateRoute  path="/services/:jfDemand?/:clientId?" exact component={LawyerServices}/>
                    {/*Data privacy Routes*/}
                    <PrivateRoute path="/data-privacy" exact component={DataPrivacy}/>
                    <PrivateRoute path="/data-privacy/client/:clientId" exact component={DataPrivacyClient}/>
                    {/*Bonuz plans*/}
                    <PrivateRoute path="/plano-essencial" exact component={EssentialPlan}/>
                    <PrivateRoute path="/plano-essencial/client/:clientId" exact component={EssentialPlanClient}/>

                    <PrivateRoute path="/plano-premium" exact component={PremiumPlan}/>
                    <PrivateRoute path="/plano-premium/client/:clientId" exact component={PremiumPlanClient}/>
                    
                    <PrivateRoute path="/admin/reports" exact component={ReportsPage} />
                    <Route>
                        <Redirect to="/dashboard"/>
                    </Route>
                </Switch>
              </MenuContext.Provider>                
            </div>
        </Router>
    );
}

export default Routes