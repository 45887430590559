import React, {useState} from "react";
import "./Table.css"; 
import StatusChips from "../StatusChips/StatusChips";
import { Table } from 'semantic-ui-react';
import AcceptModal from "../Modals/TableDemands/Accept/AcceptModal";
import InfoDemand from "../Modals/TableDemands/InfoDemand/InfoDemand";
import ClientDetails from "../Modals/ClientDetails/ClientDetails";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ReturnDemandModal from "../Modals/TableDemands/ReturnDemand/ReturnDemandModal";
import { formatedDate } from "../../helpers/Date/formatedDate";
import LanceModal from "../Modals/TableDemands/Lance/LanceModal";
import { useHistory } from "react-router-dom";
import {ReactComponent as AuctionIcon} from "../../assets/nav/DemandsAuction.svg";


const CustomTable = (props) => {

    const history = useHistory()

    const {
        isDemandPicker,
        isDemandAuction,
        dataClients,
        dataMyDemands,
        dataDemandPicker,
        dataDemandsAuction,
        reloadDemands,
        refreshDemands,
        setInfo,
        addPlan,
        clientPlans,
        loading
    } = props

    const goToDemand = (id, type) => {
        if(type === 'picker'){
            history.push(`demands-picker/${id}?${type}`)
        }else if(type === 'auction'){
            history.push(`demands-auction/${id}?${type}`)
        }else if(type === 'myDemand'){
            history.push(`demand/${id}?${type}`)
        }
    }

    const [openAcceptModal, setOpenAcceptModal] = useState(false)
    const [openReturnDemandModal, setOpenReturnDemandModal] = useState(false)
    const [openInfoDemandModal, setOpenInfoDemandModal] = useState(false)
    const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false)
    const [openLanceModal, setOpenLanceModal] = useState(false)
    const [infoDemandModalInfo, setInfoDemandModalInfo] = useState({})
    const [clientDetailsModalInfo] = useState({})
    const [viewClientModal, setViewClientModal] = useState("default")
    const [viewReturnDemandModal, setViewReturnDemandModal] =
        useState("default")
    const [viewLanceModal, setViewLanceModal] = useState("default")
    const [modalDemandId, setModalDemandId] = useState("")
    const location = useLocation()
    const pathName = location.pathname

    const handleAcceptModal = (id) => {
        setModalDemandId(id)
        setOpenAcceptModal(!openAcceptModal)
    }
    const handleClientDetailsModal = (person) => {
        setInfo(person)
        setOpenClientDetailsModal(!openClientDetailsModal)
        changeClientModalView("default")
    }
    const handleInfoDemandModal = (table) => {
        setInfoDemandModalInfo(table)
        setOpenInfoDemandModal(!openInfoDemandModal)
    }
    const handleReturnDemandModal = (id) => {
        setModalDemandId(id)
        setOpenReturnDemandModal(!openReturnDemandModal)
        setViewReturnDemandModal("default")
    }
    const handleLanceModal = (id) => {
        setModalDemandId(id)
        setOpenLanceModal(!openLanceModal)
    }

    const changeClientModalView = (view) => {
        setViewClientModal(view)
    }

    if (pathName === "/clients" || pathName === "/admin/plans") {
        return (
            <div id="table-main">
                <ClientDetails
                    handleClose={handleClientDetailsModal}
                    openModal={openClientDetailsModal}
                    info={clientDetailsModalInfo}
                    view={viewClientModal}
                    changeView={changeClientModalView}
                />
                {
                    loading && (
                        <div className="ui active loader"></div>
                    )
                }
                <Table className="client-table">
                    <Table.Header className="tb-head">
                        <Table.Row className="tb-head">
                            <Table.HeaderCell>Cliente</Table.HeaderCell>
                            <Table.HeaderCell className="person-type">
                                Tipo de pessoa
                            </Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>CNPJ/CPF</Table.HeaderCell>
                            {pathName === "/admin/plans" && (
                                <>
                                    <Table.HeaderCell>Planos</Table.HeaderCell> {/* Nova coluna */}
                                    <Table.HeaderCell>Plano Essencial</Table.HeaderCell> {/* Nova coluna */}
                                    <Table.HeaderCell>Plano Premium</Table.HeaderCell>  {/* Nova coluna */}
                                </>
                            )}
                        </Table.Row>
                    </Table.Header>

                    {dataClients && dataClients.length > 0
                        ? dataClients?.map((person) => {
                              return (
                                  <Table.Body>
                                      <Table.Row>
                                            <Table.Cell
                                                width={2}
                                                className="person-name"
                                                onClick={() =>
                                                    handleClientDetailsModal(
                                                        person,
                                                    )
                                                }
                                            >
                                                {person.firstName}{" "}
                                                {person.lastName}
                                            </Table.Cell>
                                            <Table.Cell width={1} className="person-type">
                                                {person.type === "legal"
                                                    ? "Jurídica"
                                                    : "Física"}
                                            </Table.Cell>
                                            <Table.Cell width={3}>
                                                {person.email}
                                            </Table.Cell>
                                            <Table.Cell width={3}>
                                                {person.type === "legal"
                                                    ? person.cnpj
                                                    : person.document}
                                            </Table.Cell>
                                            {pathName === "/admin/plans" && (
                                                <>
                                                    <Table.Cell>{clientPlans[person._id]}</Table.Cell>
                                                    <Table.Cell width={2}> {/* Nova célula */}
                                                        <button onClick={() => addPlan(person._id, 'plano-essencial')}>Adicionar Essencial</button>
                                                    </Table.Cell>
                                                    <Table.Cell width={2}> {/* Nova célula */}
                                                        <button onClick={() => addPlan(person._id, 'plano-premium')}>Adicionar Premium</button>
                                                    </Table.Cell>
                                                </>
                                            )}
                                      </Table.Row>
                                  </Table.Body>
                              )
                          })
                        : ""}
                    <Table.Footer>{props.pagination}</Table.Footer>
                </Table>
            </div>
        )
    } else if (isDemandPicker) {
        return (
            <div id="table-main">
                <AcceptModal
                    handleClose={handleAcceptModal}
                    openModal={openAcceptModal}
                    demandId={modalDemandId}
                    refreshDemands={refreshDemands}
                />
                <InfoDemand
                    handleClose={handleInfoDemandModal}
                    openModal={openInfoDemandModal}
                    info={infoDemandModalInfo}
                    isDemandPicker
                />
                {
                    loading && (
                        <div className="ui active loader"></div>
                    )
                }
                <Table className="demands">
                    <Table.Header className="tb-head">
                        <Table.Row className="tb-head">
                            <Table.HeaderCell>Número</Table.HeaderCell>
                            <Table.HeaderCell>status</Table.HeaderCell>
                            <Table.HeaderCell>Vencimento</Table.HeaderCell>
                            <Table.HeaderCell>Prazo</Table.HeaderCell>
                            <Table.HeaderCell>Demanda</Table.HeaderCell>
                            <Table.HeaderCell>Ações</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {dataDemandPicker && dataDemandPicker.length > 0
                        ? dataDemandPicker.map((table) => {
                              if (
                                  table.status === "waiting_lawyer_acceptance"
                              ) {
                                  return (
                                      <Table.Body>
                                          <Table.Row>
                                              <Table.Cell
                                                  onClick={() => goToDemand(table._id, 'picker',)}
                                                  width={1}
                                              >{`#000${table.code}`}</Table.Cell>
                                              <Table.Cell
                                                  onClick={() => goToDemand(table._id, 'picker')}
                                                  width={2}
                                              >
                                                  <StatusChips
                                                      status={table.status}
                                                  />
                                              </Table.Cell>
                                              <Table.Cell
                                                  onClick={() => goToDemand(table._id, 'picker')}
                                                  width={1}
                                              >
                                                  {table.finalDate !== null || formatedDate(table.finalDate) !== "Invalid Date"
                                                      ? formatedDate(table.finalDate)
                                                      : "Sem vencimento definido"}
                                              </Table.Cell>
                                              <Table.Cell
                                                  onClick={() => goToDemand(table._id, 'picker')}
                                                  width={1}
                                              >{`${table.deadline} dias úteis`}</Table.Cell>
                                              <Table.Cell
                                                  onClick={() => goToDemand(table._id, 'picker')}
                                                  width={7}
                                              >
                                                  <span>
                                                      {table.fullDescription}
                                                  </span>
                                              </Table.Cell>
                                              <Table.Cell width={1}>
                                                  <button
                                                      onClick={() =>
                                                          handleAcceptModal(
                                                              table._id,
                                                          )
                                                      }
                                                  >
                                                      Aceitar
                                                  </button>
                                              </Table.Cell>
                                          </Table.Row>
                                      </Table.Body>
                                  )
                              } else {
                                  return (
                                      <div className="empty-table">
                                          <span>
                                              Você não possuí demandas
                                              disponíveis, selecione uma Demanda
                                              Geral
                                          </span>
                                      </div>
                                  )
                              }
                          })
                        : ""}
                    <Table.Footer>{props.pagination}</Table.Footer>
                </Table>
            </div>
        )
    } else if (isDemandAuction) {
        return (
            <div id="table-main">
                <LanceModal
                    openModal={openLanceModal}
                    handleModal={handleLanceModal}
                    setView={setViewLanceModal}
                    view={viewLanceModal}
                    demandId={modalDemandId}
                />
                <InfoDemand
                    handleClose={handleInfoDemandModal}
                    openModal={openInfoDemandModal}
                    info={infoDemandModalInfo}
                    isDemandAuction
                    openLanceModal={handleLanceModal}
                />
                {
                    loading && (
                        <div className="ui active loader"></div>
                    )
                }
                <Table className="demands">
                    <Table.Header className="tb-head">
                        <Table.Row className="tb-head">
                            <Table.HeaderCell>Número</Table.HeaderCell>
                            <Table.HeaderCell>status</Table.HeaderCell>
                            <Table.HeaderCell>Serviços</Table.HeaderCell>
                            <Table.HeaderCell>Ações</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {dataDemandsAuction && dataDemandsAuction.length > 0 ? (
                        dataDemandsAuction.map((table) => {
                            return (
                                <Table.Body>
                                    <Table.Row
                                        className={
                                            table.worked_before_with_lawyer
                                                ? "worked-before-with-lawyer"
                                                : ""
                                        }
                                    >
                                        <Table.Cell
                                            onClick={() => goToDemand(table._id, 'auction')}
                                            width={1}
                                        >{`#000${table.code}`}</Table.Cell>
                                        <Table.Cell
                                            onClick={() => goToDemand(table._id, 'auction')}
                                            width={2}
                                        >
                                            <StatusChips status={"pending"} />
                                        </Table.Cell>
                                        <Table.Cell
                                            onClick={() => goToDemand(table._id, 'auction')}
                                            width={4}
                                        >
                                            {table.fullDescription}
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                            <button
                                                className="table-btn-demands-auction"
                                                onClick={() =>
                                                    handleLanceModal(table._id)
                                                }
                                            >
                                                Dar lance
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            )
                        })
                    ) : (
                        <div className="empty-table">
                            {
                                !loading && (
                                    <span>
                                        Você não possuí demandas disponíveis, selecione
                                        uma Demanda Geral
                                    </span>
                                )
                            }
                        </div>
                    )}
                    <Table.Footer>{props.pagination}</Table.Footer>
                </Table>
            </div>
        )
    } else {
        return (
            <div id="table-main">
                <ReturnDemandModal
                    handleClose={handleReturnDemandModal}
                    openModal={openReturnDemandModal}
                    view={viewReturnDemandModal}
                    setView={setViewReturnDemandModal}
                    demandId={modalDemandId}
                />
                <InfoDemand
                    handleClose={handleInfoDemandModal}
                    openModal={openInfoDemandModal}
                    info={infoDemandModalInfo}
                    isMyDemand
                    reloadDemands={reloadDemands}
                />
                {
                    loading && (
                        <div className="ui active loader"></div>
                    )
                }
                <Table className="demands">
                    <Table.Header className="tb-head">
                        <Table.Row className="tb-head">
                            <Table.HeaderCell width={1}>Número</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Cliente</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Vencimento</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Prazo</Table.HeaderCell>
                            <Table.HeaderCell width={7}>Demanda</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Ações</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {dataMyDemands && dataMyDemands.length > 0 ? (
                        dataMyDemands.map((table) => {
                            return (
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell
                                            style={{ marginLeft: "16px" }}
                                            width={2}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            {table.isAuction ? (
                                                <div className="auction-icon">
                                                    <div className="table-icon">
                                                        <AuctionIcon />
                                                    </div>
                                                    <div>{`#000${table.code}`}</div>
                                                </div>
                                            ) : (
                                                `#000${table.code}`
                                            )}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={2}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            <StatusChips
                                                status={table.status}
                                            />
                                        </Table.Cell>
                                        <Table.Cell
                                            width={1}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            {`${table.client?.firstName || 'Não encontrado'} ${table.client?.lastName}`}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={1}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            {formatedDate(table.finalDate) !== "Invalid Date"
                                                ? formatedDate(table.finalDate)
                                                : "Sem vencimento definido"}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={2}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            {table.deadline !== null
                                                ? `${table.deadline} dias úteis`
                                                : "Sem prazo definido"}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={7}
                                            onClick={() => goToDemand(table._id, 'myDemand')}
                                        >
                                            <span>{table.fullDescription}</span>
                                        </Table.Cell>
                                        <Table.Cell width={1}>
                                            <button
                                                className="table-btn"
                                                disabled={
                                                    table.status !==
                                                        "pending" ||
                                                    table.isAuction
                                                        ? true
                                                        : false
                                                }
                                                onClick={() =>
                                                    handleReturnDemandModal(
                                                        table._id,
                                                    )
                                                }
                                            >
                                                Devolver
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            )
                        })
                    ) : (
                        <div className="empty-table">
                            {
                                !loading && (
                                    
                                        <span>
                                            Você não possuí demandas disponíveis, selecione
                                            uma Demanda Geral
                                        </span>
                                )
                            }
                        </div>
                    )}
                    <Table.Footer>{props.pagination}</Table.Footer>
                </Table>
            </div>
        )
    }
}

export default CustomTable;