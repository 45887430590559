import React, {useState} from "react";
import {login} from "../../../services/authentication/authentication";
import api from "../../../services/api/api";
import {api_url} from "../../../config/environment/environmentSettings";
import { useHistory, Link } from "react-router-dom";
import { Input } from 'semantic-ui-react';
import { MdLockOutline } from "react-icons/md"
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import clsx from 'clsx';
import { styled } from '@mui/system';
import { useSwitch } from '@mui/base/SwitchUnstyled';



const Default = () => {
    
    const history = useHistory();
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [eyePassword, setEyePassword] = useState(false);
    const [checked, setChecked] = useState(false);


    const handleSingIn = async e => {
        e.preventDefault();
        if(!email || !password){
           alert("Preencha todos os campos corretamente!");
        }else{
            try{
                const response = await api.post(api_url+'/account/login', {personalEmail:email, password});
                login(response.data.access_token);
                history.push("/dashboard");
            }catch (error) {
                //console.log(error)
                alert("Erro ao logar, verifique as informações enviadas!");
            }
        }
    }

    const red = {
        500: "#f14f5c",
      };
      
      const grey = {
        400: '#BFC7CF',
        500: '#AAB4BE',
      };
      
      const BasicSwitchRoot = styled('span')`
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        margin: 10px;
        background: ${grey[400]};
        border-radius: 10px;
        cursor: pointer;
      
        &.Switch-disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      
        &.Switch-checked {
          background: ${red[500]};
        }
      `;
      
      const BasicSwitchInput = styled('input')`
        cursor: inherit;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        margin: 0;
      `;
      
      const BasicSwitchThumb = styled('span')`
        display: block;
        width: 14px;
        height: 14px;
        top: 3px;
        left: 3px;
        border-radius: 16px;
        background-color: #fff;
        position: relative;
        transition: all 200ms ease;
      
        &.Switch-focusVisible {
          background-color: ${grey[500]};
          box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }
      
        &.Switch-checked {
          left: 22px;
          top: 3px;
          background-color: #fff;
        }
      `;
      
      function BasicSwitch(props) {
        const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
      
        const stateClasses = {
          'Switch-checked': checked,
          'Switch-disabled': disabled,
          'Switch-focusVisible': focusVisible,
        };
        

        return (
          <div onClick={props.onClick}>
          <BasicSwitchRoot className={clsx(stateClasses)}>
            <BasicSwitchThumb className={clsx(stateClasses)} />
            <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
          </BasicSwitchRoot>
          </div>
          
        );
      }
          
      const handleChange = () => {
        if( !checked ) {
          setChecked(true); 
        } else {
          setChecked(false);
        }
      };
        

    return (
        <>
            <form method="post" className="default">
                <div className="row">
                    <div className="input-content">
                        <label>E-mail</label>
                        <Input icon='mail' iconPosition='left' placeholder='seunegocio@suaempresa.com' onChange={e => setEmail(e.target.value)} name="email" id="email" type="email" className="validate"/>
                    </div>
                    <div className="input-content">
                        <label>Senha</label>
                        <Input labelPosition='left'  icon='lock' iconPosition='left' placeholder='no minimo 8 dígitos' onChange={e => setPassword(e.target.value)} name="password" id="password" type={!eyePassword ? "password" : "text" } className="validate input-senha" />
                        {!eyePassword ? <FaRegEyeSlash onClick={() => setEyePassword(true)} className="icon-password"/> : <FaRegEye className="icon-password" onClick={() => setEyePassword(false)}/> }
                    </div>
                      <div className="input-content switch">
                        <BasicSwitch checked={checked} onClick={() => handleChange()}/>
                        <label>Manter conectado</label>
                      </div> 
                    <div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col s6">
                        <a href="#" className="font-pinked sub">Criar uma conta</a>
                    </div>
                    <div className="col s6">
                        <a href="#" className="font-pinked sub">Esqueceu sua senha?</a>
                    </div>
                </div> */}
                <div className="row">
                        <button onClick={(e) => handleSingIn(e)} className="waves-effect waves-light btn-small">
                            Acessar
                        </button>
                        <Link to="/login/register/1">
                          <button className="waves-effect waves-light btn-small secondary">
                            Torne-se um advogado B.onuz
                          </button>
                        </Link>             
                </div>
                <div className="row">
                  <div style={{marginBottom: "16px"}}>
                    <Link to="/login/forgotPassword" className="default-direct senha">
                      <MdLockOutline className="lock"/>
                      <span>Esqueci minha senha</span>
                    </Link>
                  </div>
                  <div className="default-direct">
                    <p>Não tem uma conta? <Link to="/login/register/1"><span>criar uma conta.</span></Link></p>
                  </div>
                </div>
            </form>
            {/* <div className="row">
                <div className="col s12">*
                    <button className="waves-effect waves-light btn-small">
                        <img src={googleIcon} className="third-party-login-img" alt="Google icon"/> Acessar com Google
                    </button>
                </div>
                <div className="col s12">
                    <button className="waves-effect waves-light btn-small">
                        <img src={facebookIcon} className="third-party-login-img inverted" alt="Facebook icon"/> Acessar com Facebook
                    </button>
                </div>
            </div>
            <div className="row">
                <a href="https://api.whatsapp.com/send?phone=5511994395810&text=Oi%2C%20tudo%20bem%3F" rel="noreferrer" target="_blank" id="whatsapp-caller">
                    <img src={whatsappIcon} alt="Whatsapp icon"/> Em caso de dúvidas, fale conosco!
                </a>
            </div> */}
        </>
    )
}

export default Default;