import React, { useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Coruja from "../../../../assets/CorujaHug.png";
import "./LanceModal.css";
import LedIcon from "../../../LedIcon/LedIcon";
import { ReactComponent as Hammer } from "../../../../assets/hammer.svg"
import { ReactComponent as BoxClock } from "../../../../assets/boxWithClock.svg";
import api from "../../../../services/api/api";

const LanceModal = (props) => {

    const {
        openModal,
        handleModal,
        demandId,
        view,
        setView
    } = props;

    const [servicePrice, setServicePrice] = useState(0)
    const [serviceDays, setServiceDays] = useState("")
    const [serviceDescription,setServiceDescription] = useState("");
    const [isBnzAdquired, setIsBnzAdquired] = useState(true);

    const handleClose = () => {
        handleModal()
        setView("default")
    }

    const handleIsAdquiredChange = () => {
        setIsBnzAdquired(!isBnzAdquired);
    };

    const bidDemand = () => {
        api.post(`/demands/bid/create/${demandId}`,{
            "bid":servicePrice,
            "deliveryDate":serviceDays,
            "description":serviceDescription,
            "isBNZAdquired": isBnzAdquired
        }).then(()=>{
            setView('finish');
        }).catch(()=>{
            window.alert('Erro ao efetuar o lance!');
            // window.location.reload();
        })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none !important',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={style} className={view === "finish" ? "box lance-modal finish" :"box lance-modal"}>
                {view === "default" ?
                <>
                    <div className="circle-led">
                        <LedIcon 
                            type="negative"
                            icon={<Hammer />}
                        />
                        <div className="line"></div>
                        <LedIcon 
                            icon={<BoxClock />}
                        />
                    </div>
                    <h2>Dê seu lance para este serviço:</h2>
                    <p>Considere o valor do seu serviço considerando imprevistos e eventuais reuniões com o cliente.</p>
                    <div className="row">
                        <div className="col s5">
                            <div className="input-field col s12">
                                <input placeholder="R$ 0,00" typy="number" onChange={e => setServicePrice(e.target.value)}/>
                            </div>
                        </div>
                        <div className="col s7">
                            <div className="input-field col s12 checkbox-container">
                                <input type="checkbox" id="isAdquired" checked={isBnzAdquired} onChange={handleIsAdquiredChange}/>
                                <label htmlFor="isAdquired">Somente BNZ Adquirido</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="cancel" onClick={() => handleClose()}>Cancelar</button>
                        <button disabled={ servicePrice  !== "" ? false : true} onClick={() => setView("default/2")}>Avançar</button>
                    </div>
                </>
                : ""
                }
                {view === "default/2" ?
                <>
                    <div className="circle-led">
                        <LedIcon 
                            type="positive"
                            icon={<Hammer />}
                        />
                        <div className="line"></div>
                        <LedIcon 
                            type="negative"
                            icon={<BoxClock />}
                        />
                    </div>
                    <h2>Insira a quantidade de dias para conclusão do serviço.</h2>
                    <p>Defina o menor prazo para ter maiores chances do cliente aceitar o serviço</p>
                    <input placeholder="0 dias" onChange={e => setServiceDays(e.target.value)}/>
                    <textarea cols="30" rows="30" onChange={e => setServiceDescription(e.target.value)} placeholder="Digite o escopo do serviço"></textarea>
                    <div>
                        <button className="cancel" onClick={() => setView("default")}>Voltar</button>
                        <button disabled={ serviceDays  !== "" ? false : true} onClick={() => bidDemand()}>Confirmar</button>
                    </div>
                </>
                : "" }
                {view === "finish" ?
                <>             
                    <h2>Obrigado! Aguarde a aprovação do cliente!!</h2>
                    <div><img src={Coruja} alt="bonuz owl"/></div>
                    <p>Você será notificado caso seja aprovado, e poderá visualizar o serviço na aba Minhas Demandas.  </p>
                    <div>
                        <button onClick={() => handleClose()}>OK</button>
                    </div>
                </>
                : ""
                }
                
            </Box>
        </Modal>
    );
};

export default LanceModal;